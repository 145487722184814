import React from 'react';
import classes from '../TransactionCategoryCard/TransactionCategoryCard.module.css';
import TransactionCategoryCard from "../TransactionCategoryCard/TransactionCategoryCard";
import {useTranslation} from "react-i18next";
import useGlobal from "../../../../../../Hooks/useGlobal";
import {useLocation} from "react-router-dom";

const TransactionCategory = ({all}) => {


    const {t} = useTranslation();
    const location = useLocation()

    const pathname = location?.pathname

    console.log("location", location?.pathname)

    const {global, setGlobal} = useGlobal();

    const categories = ['PURCHASE_FINALIZED', 'DEPOSIT', 'WITHDRAW_REQUEST', 'WITHDRAW_ACCEPT', 'WITHDRAW_REJECT', 'DEPOSIT_MANUALLY', 'WITHDRAW_MANUALLY'];

    const transactionsCategories = ['SWAP', 'DEPOSIT', 'WITHDRAW', 'SYSTEM'];
    const depositCategories = ['SWAP', 'DEPOSIT', 'WITHDRAW', 'SYSTEM'];
    const withdrawCategories = ['SWAP', 'DEPOSIT', 'WITHDRAW', 'SYSTEM'];


    const showAllHandler = () => {

        let newGlobal = {...global}

        newGlobal = {
            ...newGlobal,
            activeActionSheet: {
                menu: false,
                select: false,
            },
            selectType: null,
            selected_tx_h_category: null,
        }

        setGlobal(newGlobal)

    }



    const content = () => {

        return <>

            {all && <div className={`${classes.asset} row jc-between ai-center py-1 my-2 px-3 rounded-8`}  onClick={()=>showAllHandler()}>
                <span>{t("all")}</span>
            </div>}

            {/*{content()}*/}



            {
                pathname?.includes("transactions") && transactionsCategories.map(data => <TransactionCategoryCard data={data}/>)
            }

        </>
    }

    return (
        content()
    );
};

export default TransactionCategory;
