import React from 'react';
import classes from './SwapHistory.module.css'
import {useTranslation} from "react-i18next";

const SwapHistory = () => {
    const {t} = useTranslation();

    return (
        <div className={`my-15 width-100 flex jc-center ai-center`}>
            <span className={`my-10 font-weight-bold text-orange`}>{t("soon")}</span>
        </div>
    );
};

export default SwapHistory;
