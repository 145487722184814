import React, {useState} from 'react';
import classes from './FinalizeOrder.module.css';
import {Trans, useTranslation} from "react-i18next";
import useAuth from "../../../../../../Hooks/useAuth";
import {BN, ratePrint} from "../../../../../../utils/utils";
import Button from "../../../../../../components/Button/Button";
import Loading from "../../../../../../components/Loading/Loading";
import TextInput from "../../../../../../components/TextInput/TextInput";
import {finalizeOrder, reserveOrder} from "../../../../../../api/exchange";
import toast from "react-hot-toast";
import {useGetDashboard, useGetUserWallet} from "../../../../../../query";
import Icon from "../../../../../../components/Icon/Icons";
import useGlobal from "../../../../../../Hooks/useGlobal";
import i18next from "i18next";

const FinalizeOrder = () => {

    const {t} = useTranslation();

    const {auth} = useAuth();
    const {global, setGlobal} = useGlobal();


    const [loading, setLoading] = useState(false)
    const [isInputVisible, setIsInputVisible] = useState({
        password: false,
    });

    const {refetch} = useGetUserWallet()


    const sourceAmount = new BN(global?.reserveOrderRes?.sourceAmount)
    const guaranteedDestAmount = new BN(global?.reserveOrderRes?.guaranteedDestAmount)
    const sourceSymbol = global?.reserveOrderRes?.sourceSymbol
    const destSymbol = global?.reserveOrderRes?.destSymbol
    const calculatedRate = guaranteedDestAmount.dividedBy(sourceAmount)
    const reserveNumber = global?.reserveOrderRes?.reserveNumber
    const sourceSymbolPrecision= global?.currencies[sourceSymbol]?.precision || 2
    const destSymbolPrecision= global?.currencies[destSymbol]?.precision || 2


    const [input, setInput] = useState({
        txPassword: {value: "", error: []},
    });


    const inputHandler = (e) => {
        let errorMessage = []
        let inputVal = e.target.value
        if (typeof e.target.dataset.min !== undefined && e.target.value.length < e.target.dataset.min) {
            errorMessage.push(<Trans
                i18nKey="Login.minInput"
                values={{
                    name: t(e.target.dataset.name),
                    min: e.target.dataset.min
                }}
            />)
        }
        let prevState = {
            ...input,
            [e.target.dataset.name]: {...input[e.target.dataset.name], value: inputVal, error: errorMessage}
        }
        setInput(prevState)
    }

    const isFormValid = () => {
        let inputs = {...input}

        const hasError = Object.values(input).find(input => input.error.length > 0)
        if (hasError) return false
        let isEmpty = false

        for (const key in inputs) {

            if (inputs[key].value.length === 0) {
                isEmpty = true
                inputs = {
                    ...inputs,
                    [key]: {
                        ...inputs[key],
                        error: [<Trans
                            i18nKey="Login.emptyInput"
                            values={{
                                name: t(key),
                            }}
                        />]
                    }
                }
            }
        }

        setInput(inputs);
        return !isEmpty;
    }


    const submit = async (e) => {
        e.preventDefault();

        if (!isFormValid()) return false;

        setLoading(true)

        const finalizeOrderParam = {
            "password": input?.txPassword?.value,
        }

        finalizeOrder(reserveNumber, finalizeOrderParam, auth?.token)
            .then(async (res) => {


                toast.success(t("successTxfinalize"))
                refetch()

                setGlobal({
                    ...global,
                    activeActionSheet: {
                        menu: false,
                        select: false,
                    },
                    selected_exchange_base: "",
                    selected_exchange_quote: "",
                    reserveOrderRes: {},
                    selectType: "---"
                })

            }).catch(err => {
                if (i18next.exists('ErrorMessages.'+ err?.response?.data?.message)) {
                    toast.error(t("ErrorMessages."+ err?.response?.data?.message))
                }
                else {
                    toast.error(t("serverError"))
                }
        }).finally(() => {
            setLoading(false)
        })

    }


    const buttonTitleHandler = () => {
        if (loading) return <Loading type="text"/>
        return t('submit')
    }

    return (
        <form onSubmit={submit} className={`${classes.container} column jc-between ai-center height-100 py-5`}>

            <div className={`font-weight-bold fs-02 `}>{t("confirmTx")}</div>
            



            <div className={`column jc-center ai-center width-100`}>
                <div className={`width-90 rounded-8 row jc-between ai-center ${classes.amountBox} px-5 py-1 my-1 position-relative `}>
                    <span className={`width-30`}>{t("payAmount")}</span>
                    <div className={`width-70 row jc-end ai-center`}>
                        <span className={`fs-02 font-weight-bold`}>{ new BN(sourceAmount).decimalPlaces(sourceSymbolPrecision).toFormat() } </span>
                        <span className={`${classes.space}`}>{global?.currencies[sourceSymbol]?.alias}</span>
                    </div>
                </div>

                <div className={`width-90 rounded-8 row jc-between ai-center ${classes.amountBox} px-5 py-1 my-1 position-relative `}>
                    <span className={`width-30`}>{t("calculatedRate")}</span>
                    <div className={`width-70 row jc-end ai-center`}>
                        <span className={`fs-02 font-weight-bold ${classes.name}`}>{ ratePrint(calculatedRate, destSymbolPrecision)  } </span>
                        <span className={`${classes.space}`}>{global?.currencies[destSymbol]?.alias}</span>
                    </div>
                </div>


                <div className={`width-90 rounded-8 row jc-between ai-center ${classes.amountBox} px-5 py-1 my-1 position-relative `}>
                    <span className={`width-30`}>{t("receiveAmount")}</span>
                    <div className={`width-70 row jc-end ai-center`}>
                        <span className={`fs-02 font-weight-bold`}>{ new BN(guaranteedDestAmount).decimalPlaces(destSymbolPrecision).toFormat()  } </span>
                        <span className={`${classes.space}`}>{global?.currencies[destSymbol]?.alias}</span>
                    </div>
                </div>
            </div>


            <TextInput
                value={input.txPassword.value}
                label={t('txPassword')}

                id="txPassword"
                labelFor="txPassword"
                //placeholder={t('Login.mobilePh')}
                data-name="txPassword"
                data-type="input"
                data-min={8}
                //maxLength="10"
                onchange={(e) => inputHandler(e)}
                alerts={input.txPassword.error}
                inputClass={`width-90 my-2`}
                type={isInputVisible.password ? "text" : "password"}
                icon={
                    <Icon
                        iconName={`${isInputVisible.password ? ' icon-eye' : 'icon-eye-off'} fs-03 flex cursor-pointer hover-text`}
                        onClick={() => setIsInputVisible({
                            ...isInputVisible,
                            password: !isInputVisible.password
                        })}
                    />
                }
            />


            <Button
                type="submit"
                buttonClass={`${classes.thisButton} width-100 cursor-pointer rounded-100-p mb-1`}
                buttonTitle={buttonTitleHandler()}
                disabled={loading}
            />

            

        </form>
    );
};

export default FinalizeOrder;
