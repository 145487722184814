import React from 'react';
import classes from './Select.module.css';
import {useTranslation} from "react-i18next";
import ScrollBar from "../../../../components/ScrollBar";
import Currencies from "./Module/Currencies/Currencies";
import FinalizeOrder from "./Module/FinalizeOrder/FinalizeOrder";
import TransactionCategory from "./Module/TransactionCategory/TransactionCategory";
import TransactionSize from "./Module/TransactionSize/TransactionSize";
import useGlobal from "../../../../Hooks/useGlobal";
import Gateways from "./Module/Gateways/Gateways";
import UserBankAccount from "./Module/UserBankAccount/UserBankAccount";

const Select = () => {

    const {t} = useTranslation();

    const {global} = useGlobal();


    const content = () => {


        if (global?.selectType === "exchange_base") {
            return <Currencies/>
        }

        /*if (auth?.selectType === "exchange_base") {
            return <Currencies filter={auth?.selected_exchange_quote}/>
        }*/

        if (global?.selectType === "exchange_quote") {
            return <Currencies filter={global?.selected_exchange_base} />
        }


        if (global?.selectType === "finalize_order") {
            return <FinalizeOrder/>
        }


        if (global?.selectType === "tx_h_coin") {
            return <Currencies all={true}/>
        }
        if (global?.selectType === "tx_h_category") {
            return <TransactionCategory all={true}/>
        }
        if (global?.selectType === "tx_h_size") {
            return <TransactionSize all={true}/>
        }



        if (global?.selectType === "deposit_coin") {
            return <Currencies/>
        }
        if (global?.selectType === "withdrawal_coin") {
            return <Currencies/>
        }


        if (global?.selectType === "deposit_network") {
            return <Gateways type="deposit_network"/>
        }

        if (global?.selectType === "withdrawal_network") {
            return <Gateways type="withdrawal_network"/>
        }

        if (global?.selectType === "OffChainWithdraw_address") {
            return <UserBankAccount/>
        }


        if (global?.selectType === "transfer_coin") {
            return <Currencies/>
        }






     /*   if (auth?.selectType === "withdrawal_network") {
            return <Network type="withdrawal_network"/>
        }*/



    }

    return (
        <div className={`width-86 m-auto ${classes.container} column jc-between ai-center pb-2`}>

            <ScrollBar>
                {content()}
            </ScrollBar>


        </div>
    );
};

export default Select;
