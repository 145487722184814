import React, {useState} from 'react';
import classes from './RecentTxHistoryCard.module.css'
import {useTranslation} from "react-i18next";
import Date from "../../../../../../components/Date/Date";
import moment from "moment-jalaali";
import {BN, ratePrint} from "../../../../../../utils/utils";
import useGlobal from "../../../../../../Hooks/useGlobal";

const RecentTxHistoryCard = ({data}) => {

    const {t} = useTranslation();

    const {global} = useGlobal();

    const [isOpen, setIsOpen] = useState(false);


    return (
        <div className={`width-90 py-2 columns jc-between ai-center ${classes.container}`}>
            <div className={`width-100 column jc-between ai-center ${classes.rectangle}`} onClick={()=> data?.description && setIsOpen(prevState => !prevState)}>
                <div className={`width-100 row jc-between ai-center`}>
                    <div className={`row`}>
                        <span><Date date={data?.date}/></span>
                        <span className={`${classes.spacing}`}/>
                        <span className={``}>{moment.utc(data?.date).local().format("HH:mm:ss")}</span>
                    </div>
                    <span className={`row jc-center ai-center`}>{t("TransactionCategory."+ data?.category)}</span>
                </div>
                <div className={`width-100 row jc-between ai-center`}>
                    <span>{t("History.balanceChange")}</span>
                    <div className={`direction-ltr row ${new BN(data?.balanceChange).isLessThan(0) ? "text-red" : "text-green"}`} style={{alignItems:"baseline"}}>
                        <span className={`fs-03`}>{ ratePrint(data?.balanceChange, global?.currencies[data?.currency]?.precision)}</span>
                        <span className={`${classes.spacing}`}/>
                        <span>{data?.currency}</span>
                    </div>
                </div>
                <div className={`width-100 row jc-between ai-center`}>
                    <span>{t("History.balance")}</span>
                    <div className={`direction-ltr row`} style={{alignItems:"baseline"}}>
                        <span className={`row jc-center ai-center`}>{ratePrint(data?.balance, global?.currencies[data?.currency]?.precision)}</span>
                        <span className={`${classes.spacing}`}/>
                        <span>{data?.currency}</span>
                    </div>
                </div>
            </div>
            {isOpen && <div className={`width-90 column jc-start ai-start mt-2 ${classes.rectangle}`}>
                <span>{t("History.description")}</span>
                <span>{data?.description ? data?.description : "---"}</span>
            </div>}
        </div>
    );
};

export default RecentTxHistoryCard;
