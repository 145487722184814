import React, {useEffect, useState} from 'react';
import classes from './Withdrawal.module.css';
import {Trans, useTranslation} from "react-i18next";
import ScrollBar from "../../../../components/ScrollBar";
import TextInput from "../../../../components/TextInput/TextInput";
import WithdrawalAddress from "./Module/WithdrawalAddressByNetwork/WithdrawalAddressByNetwork";
import useGlobal from "../../../../Hooks/useGlobal";
import WithdrawMethod from "./Module/WithdrawMethod/WithdrawMethod";
import WithdrawType from "./Module/WithdrawType/WithdrawType";
import OnChainWithdraw from "./Module/OnChainWithdraw/OnChainWithdraw";
import OffChainWithdraw from "./Module/OffChainWithdraw/OffChainWithdraw";

const Withdrawal = () => {


    const {t} = useTranslation();

    const {global, setGlobal} = useGlobal();


    const [input, setInput] = useState({
        asset: {value: "", error: []},
    });

    useEffect(()=>{

        let newInputData = {...input}

        if (global?.selected_withdrawal_coin) {
            newInputData = {...newInputData,
                asset : { value: global?.selected_withdrawal_coin, error: []},
            }
            /*setGlobal({...global,
                selected_withdrawal_network: null
            })*/
        }

        /*if (global?.selected_withdrawal_network && (global?.selected_withdrawal_coin === input?.asset?.value)) {
            /!*newInputData = {...newInputData,
                network: {value: global?.selected_withdrawal_network, error: []}
            }*!/
            setGlobal({...global,
                selected_withdrawal_network: null
            })
        }*/


        /*if (!global?.selected_withdrawal_network) {
            newInputData = {...newInputData,
                network: {value: "", error: []}
            }
        }*/


        setInput(newInputData)

    },[global?.selectType])




    return (
        <div className={`${classes.container} width-86 height-100 rounded-8 m-auto column jc-center ai-center pb-5`}>
            <ScrollBar>
                <div className={`column jc-center ai-center width-100 mt-4 mb-2`}>
                    <TextInput
                        value={input.asset.value}
                        // value={t('currency.'+ input.asset.value)}
                        type="text"
                        label={t('asset')}
                        //placeholder={t('Login.mobilePh')}
                        data-name="asset"
                        data-type="input"
                        data-min={2}
                        //maxLength="10"
                        /*onchange={(e) => inputHandler(e)}*/
                        alerts={input.asset.error}
                        inputClass={`width-85 my-1`}
                        select={true}
                        readOnly={true}
                        selectType="withdrawal_coin"
                    />



                    { input?.asset?.value && <>

                        {global?.currencies[input?.asset?.value]?.availableGatewayType === "OnChain" && <OnChainWithdraw/>}
                        {global?.currencies[input?.asset?.value]?.availableGatewayType === "OffChain" && <OffChainWithdraw/>}

                        {/*<WithdrawType asset={input?.asset?.value}/>*/}


                    </> }



                   {/* <TextInput
                        value={input.network.value}
                        // value={t('currency.'+ input.asset.value)}
                        type="text"
                        label={t('network')}
                        //placeholder={t('Login.mobilePh')}
                        data-name="network"
                        data-type="input"
                        data-min={2}
                        //maxLength="10"
                        onchange={(e) => inputHandler(e)}
                        alerts={input.network.error}
                        inputClass={`width-85 my-1`}
                        select={true}
                        readOnly={true}
                        selectType="withdrawal_network"
                    />*/}


                </div>



                {/*{(input?.asset?.value && input?.network?.value) && <WithdrawalAddressByNetwork asset={input?.asset?.value} network={input?.network?.value}/>}
*/}
            </ScrollBar>

        </div>
    );
};

export default Withdrawal;
