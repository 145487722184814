import React from 'react';
import classes from './CurrencyCard.module.css'
import {useTranslation} from "react-i18next";
import useAuth from "../../../../../../Hooks/useAuth";
import useGlobal from "../../../../../../Hooks/useGlobal";
import toast from "react-hot-toast";

const CurrencyCard = ({data}) => {

    const {t} = useTranslation();

    const {global, setGlobal} = useGlobal();

    const isDeposit = global?.selectType === "deposit_coin"
    const isWithdraw = global?.selectType === "withdrawal_coin"

    const isAllowed = (global?.selectType === "deposit_coin" && data?.depositAllowed)
        || (global?.selectType === "withdrawal_coin" && data?.withdrawAllowed)
        || (global?.selectType === "exchange_base") || (global?.selectType === "exchange_quote")
        || global?.selectType === "tx_h_coin"


    const selectedCoinHandler = (coin) => {

        if (!isAllowed) {
            return toast.error( isDeposit ?  t("depositCurrencyIsNotAllowed") : t("withdrawCurrencyIsNotAllowed"))
        }

        let newGlobal = {...global}

        newGlobal = {
            ...newGlobal,
            activeActionSheet: {
                menu: false,
                select: false,
            },
            selected_withdrawal_network: global?.selected_withdrawal_coin === coin ? global?.selected_withdrawal_network : null,
            selected_deposit_network: global?.selected_deposit_coin === coin ? global?.selected_deposit_network : null,
            selected_withdrawal_iban: null,
            selectType: null,
            ["selected_" + global?.selectType]: coin
        }

        setGlobal(newGlobal)

    }

    return (
        <div className={`${classes.asset} ${!isAllowed && classes.disabled  } row jc-between ai-center py-1 my-2 px-3 rounded-8`} onClick={()=>selectedCoinHandler(data?.symbol)}>
            <div className={`width-30 flex jc-start ai-center`}>
                <img src={data?.icon} alt="" className={`${classes.icon} ${!isAllowed && "grayscale-filter-100"}`}/>
            </div>
            <div className={`width-35 row jc-start ai-center`}>
                <span className={`abridge`}>{data?.alias}</span>
            </div>
            <div className={`width-35 row jc-end ai-center`}>
                <span className={`text-gray`}>{data?.symbol}</span>
            </div>
        </div>
    );
};

export default CurrencyCard;
